.modal{
    padding: 6rem 2rem;
    .card{
        max-width: 480px;
        margin: auto;
    }
}
.modal-content{
    border:none;
    border-radius: 0;
}