.landing-page__header {
    display: flex;
    flex-direction: row;
    padding:1.5rem;
}
.landing-page__nav {
    display: flex;
    flex-direction: column;
    list-style-type: none;
}
.landing-page__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;
    padding: 0 1em;
}
.landing-page__content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
}
.landing-page__banner {
    padding: 1.5rem;
    margin-bottom: 2rem;
    border: 1px solid #ADB3B8;
    background-color: #F7F7F8;
    display: flex;
    
}
.landing-page__nav-item {
    margin: 0 1em 1em;
}
.landing-page__card {
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.landing-page__login {
    margin-top: 2rem;
}
.brand {
    width: 300px;
}
.brand-link {
    display: block;
}
.brand-link:focus {
    outline: 1px dotted #D74100;
    background: none;
}
.four-oh-four {
    font-size: 100px;
    font-weight: bold;
    text-align: center;
    line-height: 6rem;
    margin-top: -2rem;
}

@media only screen and (min-width: 1025px) {
    .landing-page {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .landing-page__header {
        flex-direction: row;
        justify-content: space-between;
    }
    .landing-page__body {
        justify-content: center;
        /*padding-bottom: 10%;*/
        margin: 0;
    }
    .landing-page__nav {
        flex-direction: row;
    }
    .landing-page__content {
        flex-direction: row;
        padding: 0;
    }
    .landing-page__card {
        margin: 1rem;
        &:last-child {
            margin-right: 0;
        }
    }
    .landing-page__banner {
        padding: 1.5rem;
        margin-bottom: 2rem;
        border: 1px solid #ADB3B8;
    }
}
