.card {
    border-radius: 0;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #DEE1E3;
    margin: 0 1rem 1rem 0;

    .card-header {
        border-radius: 0;
        background: $white;
        color: $su-blue-primary;
        border-bottom: 2px solid #000E54;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
        }
    }
}