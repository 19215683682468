

table{
  padding-bottom:16px;
}
td{
  flex-direction: column;
}
tr{
  border-bottom: 1px solid $su-gray-light-40;
  &:last-child{
    border-bottom: none;
  }
}

dt{
  margin: 0 1em 0.6em 0;
}
dd{
  margin: 0 1em 1em 0;
}
.button-cell{
  .button{
    float:right;
  }
}
.button{
    text-decoration: none;
    display: inline-block;
    line-height: 1.8rem;
}
#content-wrapper{
  background: #F7F7F8;
  position: relative;
  padding: 1.6rem;
}

.main-tab{
  padding-bottom:32px;
  display:flex;
  flex-direction:row;
  width:100%;
}
.plugin-tab{
  padding-bottom:32px;
}
.mobile-data{
  width:50%;
}
.mobile-td{
  text-align:left;
}

.updates{
  display:flex;
  justify-content:space-between;
  width:100%;
}

.site-tab{
  padding-bottom:32px;
}
.r-head{
  display:flex;
  justify-content:space-between;
  flex-direction: row;
}
.r-foot{
  display:flex;
  justify-content: flex-end;
  width:100%;
}
.c-head{
  display:flex;
  justify-content:space-between;
  flex-direction: row;
}
.c-foot{
  display:flex;
  justify-content: flex-end;
  width:100%;
}
.copy-head{
  display:flex;
  justify-content:space-between;
  flex-direction: row;
}
.copy-foot{
  display:flex;
  justify-content: flex-end;
  width:100%;
}
html {
  font-family: ProximaNova,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background: #fff;
  color:#000E54;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

*,html,body {
 box-sizing: border-box;
}

body {
 padding: 0;
 font-size: 1em;
 line-height: 1.25em;
 background-color: #ffffff;
}

.content {
  margin: 0 auto;
  padding: 0;
  /* max-width: 800px; */
  width: 100%;
  height: auto;
}

.accordion-wrapper{
  margin:0 auto;padding:0;position:relative
}
.accordion-wrapper .accordion-panel {
  margin: 10px auto;
  padding: 0;
  position: relative
}

.accordion-wrapper .accordion-panel .accordion-title {
  margin: 0 0 10px;
  padding: 0 0 4px;
  border-bottom: 1px #efefef solid
}

.accordion-wrapper .accordion-panel .accordion-title a:active,
.accordion-wrapper .accordion-panel .accordion-title a:link,
.accordion-wrapper .accordion-panel .accordion-title a:visited {
  display: block;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: #060505;
  transition: color .8s
}

.accordion-wrapper .accordion-panel .accordion-title a:active:after,
.accordion-wrapper .accordion-panel .accordion-title a:link:after,
.accordion-wrapper .accordion-panel .accordion-title a:visited:after {
  content: '+';
  position: absolute;
  right: 0;
  font-size: 20px;
  line-height: 20px;
  color: #ec3731;
  transition: all .5s
}

.accordion-wrapper .accordion-panel .accordion-title a:hover,
.accordion-wrapper .accordion-panel .accordion-title a:hover:visited {
  color: #ec3731;
}

@media screen and (max-width:767px) {
  .accordion-wrapper .accordion-panel .accordion-title {
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 10px
  }
}

@media screen and (max-width:400px) {
  .accordion-wrapper .accordion-panel .accordion-title {
      font-size: 14px;
      line-height: 18px;
      padding-bottom: 6px
  }
}

.accordion-wrapper .accordion-panel .accordion-content {
  margin: 0;
  padding: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all .3s
}

.accordion-wrapper .accordion-panel .accordion-title.is-active a:active:after,
.accordion-wrapper .accordion-panel .accordion-title.is-active a:hover:after,
.accordion-wrapper .accordion-panel .accordion-title.is-active a:hover:visited:after,
.accordion-wrapper .accordion-panel .accordion-title.is-active a:link:after,
.accordion-wrapper .accordion-panel .accordion-title.is-active a:visited:after {
  color: #060505;
  content: "-";
}

.accordion-wrapper .accordion-panel .accordion-content.is-active {
  padding: 0;
  margin: 0;
  max-height: 15000px;
  opacity: 1;
  transition: all 1s
}
.r-index-head{
  display:flex;
  justify-content:space-between;
  flex-direction: row;
}
.c-index-head{
  display:flex;
  justify-content:space-between;
  flex-direction: row;
}
.head{
  display:flex;
  justify-content:space-between;

  form{
    text-align:right;
  }
}

h1{
  color:#000E54;
}
.links a
{
  color:#F76900;
  text-decoration: none;
  padding:8px;
}
.accordion-content li{
  padding:16px;
  text-decoration: none;

}
.accordion-content a:link{
  text-decoration: none;
}
.accordion-content button{
  margin-top:16px;
  text-decoration: none;
}

.plain-button button
{
  all:unset;
  color: #000E54;

}

.plain-button button:hover {
  color: #D74100;
}
.c-options{
  display:flex;
  flex-direction:row;
}
.main-stat{
  display:flex;
  flex-direction: row;
}
.chart-container{
  display:flex;
  flex-direction: column;
  width:50%;
}
.main-stat .main-tab{
  display:flex;
  flex-direction: column;
}
.main-tab th{
  font-size:16px;
}
.main-stat .main-tab td{
  text-align:right;
}
.a-tab{
  display:flex;
  justify-content: space-between;
}
li{
  list-style-type:none;
}

.site-head{
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  width:100%;
}
/* class="fa fa-minus" aria-hidden="true" */
@media only screen and (max-width: 855px) {
  .main-stat{
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-content:center;
      width:100%;
      padding-top:0px;
      margin:0;
  }
  .container{
      width:100%;
      margin:0;
      padding:10px;
  }
  .main-tab{
      padding-bottom:20px;
      margin:0;
      width:100%;
  }
  .main-tab h2{
      font-size:12px;
  }
  .site-head-heading{
      flex-grow:1;
  }
  .head{
      display:flex;
      flex-direction: column;
  }
  .chart-container{
      width:100%;
  }
  .updates{
      display:flex;
      justify-content:space-between;
      width:100%;
  }
  .site-tab{
      display:flex;
      justify-content: space-between;
  }
}

.dropdown-menu{
  color: inherit;
  border-radius: 0;
}
.dropdown-item{
  color: $su-blue-primary;

  &:hover,&:focus,&:active{
    color: #fff;
    background-color: #D74100;
  }
}