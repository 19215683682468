.alert{
    p{
        margin:0;
        display: inline-block;
    }
    ul{
        margin-bottom: .6rem;
    }
}
.alert-info {
    color: $su-blue-primary;
}
.alert-error{
    background-color: #fbe5e1;
    color: #a62d19;
    ul{
        color: #a62d19;
    }
}
