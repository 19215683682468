.footer-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1.6rem 1rem;
    box-shadow: 0px -3px 6px #aeaeae29;
    z-index: 1;
    position: relative;

    span{
        margin-bottom: 1em;
        padding: 0 20px;
    }

    @media (min-width: 768px){
        flex-direction: row;
        span{
            margin-bottom: 0;
        }
    }
}