.navbar {
    box-shadow: 0px 3px 6px #aeaeae29;
    z-index: 1;
    .icon{
        font-size: 1.7rem;
        margin-right: .6rem;
        position: relative;
        top: 3px;
    }
    .icon-chevron-down{
        font-size: 0.8em;
        margin-left: 0.8em;
    }
    a{
        text-decoration: none;
    }
}
.brand {
    width: 225px !important;
    p {
        font-weight: bold;
        margin: 0;
        padding: 0.6em;
        padding: 10px;
    }
}

.logo-brand {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-height: 70px;
    }
    span, h1 {
        display: block;
        margin: 0;
        margin-left: 20px;
    }
    a {
        display: flex;
        flex: 1 1 auto;
        width: auto;
    }
}