.sidebar {
    background-color: $su-blue-primary;
    border-right:1px solid #E2E4E6;
    color:#fff;
    display:flex;
    flex-direction:column;
    align-content: center;
    align-items:center;
    min-height: calc(100vh - 174px);
    margin-top: 0;
    .navbar-nav{
        padding-top: 0.6em;
        margin-top:0;
    }

    .nav-item {
        border-bottom: 1px solid $su-blue-primary-80;
        margin-bottom: 0;
        &:last-child{
            border-bottom: none;
        }
        .nav-link {
            color: #fff;
            text-decoration: none;

            &:hover{
                color: $su-orange-primary;
            }
        }
        @media (min-width:768px){
            .nav-link{
                padding: 1rem 1rem 1rem 1.7rem;
            }
            .fas{
                margin-right: 1rem;
                font-size: 1.4rem;
            }
        }
        .icon-chevron-right {
            display: none;
            font-size: .8em;
            margin-left: 5px;
        }
        &.active {
            a.nav-link {
                color: #FFFFFF !important;
                background: #D74100;
            }
        }
    }

    [class^="icon-"], [class*=" icon-"]{
        width: 1.7em;
        text-align: center;

        @media (min-width:768px) {
            margin-right: .5em;
        }
    }
}
