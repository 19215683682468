

.login-form-shadow {
  box-shadow: 0 4px 8px 0 #B3B3B3, 0 6px 20px 0 #A0A0A0;
}

.su-logo {
  max-width: 342px;
}

.border{
  border: 1px solid #A9ADB3;
}
