.hidden{
    display:none !important;
}

.loading-elipsis:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px;
}

#sitePreviewImage {
    height: 100%;
   .loading-container  {
        position: relative;
        height: auto;
        .loading-image {
            width: 100%;
            height: 100%;
        }
        .loading-text {
            color: white;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -10%;
        }
    }
}

@keyframes ellipsis {
    to {
        width: 1em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1em;
    }
}