@font-face {
  font-family: 'orange-icons';
  src:  url('https://fastly.cdn.syracuse.edu/fonts/orange-icons.woff2'),
    url('https://fastly.cdn.syracuse.edu/fonts/orange-icons.woff') format('woff'),
    url('https://fastly.cdn.syracuse.edu/fonts/orange-icons.ttf') format('truetype'),
    url('https://fastly.cdn.syracuse.edu/fonts/orange-icons.eot') format('embedded-opentype'),
    url('https://fastly.cdn.syracuse.edu/fonts/orange-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'orange-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  width: 1em;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-left{
  margin-right: 4px;
  position: relative;
  top: 2px;
  left: -8px;
}

.icon-network-wired:before {
  content: "\e901";
}
.icon-photo-video:before {
  content: "\e902";
}
.icon-bell-open:before {
  content: "\e903";
}
.icon-news:before {
  content: "\e905";
}
.icon-home1:before {
  content: "\e906";
}
.icon-brush-solid:before {
  content: "\e907";
}
.icon-pencil:before {
  content: "\e908";
}
.icon-alert .path1:before {
  content: "\e909";
  color: rgb(35, 31, 32);
}
.icon-alert .path2:before {
  content: "\e90a";
  margin-left: -1.2216796875em;
  color: rgb(0, 0, 0);
}
.icon-dashboard:before {
  content: "\e90b";
}
.icon-calendar:before {
  content: "\e90c";
}
.icon-user-solid:before {
  content: "\e90d";
}
.icon-chart-open:before {
  content: "\e90e";
}
.icon-twitter-square:before {
  content: "\e90f";
}
.icon-twitter:before {
  content: "\e910";
}
.icon-facebook:before {
  content: "\e911";
}
.icon-down-arrow:before {
  content: "\e912";
}
.icon-right-arrow:before {
  content: "\e913";
}
.icon-hamburger:before {
  content: "\e914";
}
.icon-times:before {
  content: "\e915";
}
.icon-telephone:before {
  content: "\e916";
}
.icon-search:before {
  content: "\e917";
}
.icon-mail:before {
  content: "\e918";
}
.icon-chevron-up:before {
  content: "\e919";
}
.icon-chevron-down:before {
  content: "\e91a";
}
.icon-chevron-left:before {
  content: "\e91b";
}
.icon-chevron-right:before {
  content: "\e91c";
}
.icon-download:before {
  content: "\e91d";
}
.icon-checkmark:before {
  content: "\e91e";
}
.icon-refresh:before {
  content: "\e91f";
}
.icon-file-open:before {
  content: "\e920";
}
.icon-file-solid:before {
  content: "\e921";
}
.icon-cog-open:before {
  content: "\e922";
}
.icon-cog-solid:before {
  content: "\e923";
}
.icon-home:before {
  content: "\e900";
}
.icon-newspaper:before {
  content: "\e904";
}
